
const $videos = document.querySelectorAll('.you-tube-video');
const $vimeoVideos = document.querySelectorAll('.vimeo-video');
var player;


if ($videos && $videos.length > 0) {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    setTimeout(() => { firstScriptTag.parentNode.insertBefore(tag, firstScriptTag); }, 1000);
}
if ($vimeoVideos && $vimeoVideos.length > 0) {
    setTimeout(() => {
        $vimeoVideos.forEach($video => {
            const $iframe = $video.querySelector('iframe');
            const id = $video.dataset.videoId;
            $iframe.src = `https://player.vimeo.com/video/${id}?h=87fde58755&color=ffffff&title=0&byline=0&portrait=0`;
            $video.querySelector('[data-video-loading]').style.display = 'none';
            $video.querySelector('.embed-responsive').classList.remove('d-none');

        });
    }, 1000);
}

window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
    //console.log("Youtube API ready!!");
    $videos.forEach($video => {
        const $iframe = $video.querySelector('.embed-responsive-item');
        const id = $video.dataset.videoId;
        player = new YT.Player($iframe, {
            videoId: id,
            rel: 0,
            host: 'https://www.youtube-nocookie.com',
            events: {
                'onReady': function (event) {
                    // hide other items
                    $video.querySelector('[data-video-loading]').style.display = 'none';
                    $video.querySelector('.embed-responsive').classList.remove('d-none');
                }
            }
        });
    });
}